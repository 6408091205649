import classNames from 'classnames';
import PropTypes from 'prop-types';

import { PUMPKIN_NOW_HOURS } from '@pumpkincare/shared';
import { Bolt, LegalBody } from '@pumpkincare/shared/ui';

import styles from './pumpkin-now-service-hours.css';

function PumpkinNowServiceHours({ classes }) {
  return (
    <div className={classNames(styles.root, classes.root)}>
      <LegalBody isBold className={styles.align}>
        <Bolt /> PumpkinNow™ Service Hours
      </LegalBody>
      {PUMPKIN_NOW_HOURS.map(hour => (
        <LegalBody key={hour}>{hour}</LegalBody>
      ))}
      <LegalBody className={styles.note}>
        Please note: This usually takes around 15 minutes. If filing outside service
        hours, we will process it on the next business day.
      </LegalBody>
    </div>
  );
}

PumpkinNowServiceHours.defaultProps = {
  classes: {},
};

PumpkinNowServiceHours.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};

export default PumpkinNowServiceHours;
