import { useMutation } from 'react-query';

import { postUserPetVet } from './user-service';

export function usePostUserPetVet(options = {}) {
  return useMutation(
    ({ userId, petId, payload }) => postUserPetVet(userId, petId, payload),
    options
  );
}
