import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  FORMATTED_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body1, Bolt, ButtonStyles, LegalBody, Modal } from '@pumpkincare/shared/ui';

import PumpkinNowServiceHours from '../../pumpkin-now-service-hours';

import styles from './pumpkin-now-qualified-modal.css';

function PumpkinNowQualifiedModal({ isEstimate, isQualified, onClose, minimum }) {
  return (
    <Modal
      classes={{ container: styles.root, content: styles.content }}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      onClose={onClose}
    >
      {isQualified ? (
        <>
          <Bolt
            width={45}
            height={80}
            viewBox='0 0 45 80'
            className={styles.boltBig}
          />
          <h5 id='modal-title' className={styles.header}>
            A claim specialist will take a look right away
          </h5>
          <Body1 id='modal-description'>
            This {isEstimate ? 'estimate' : 'claim'} may qualify for PumpkinNow™
            expedited processing (restrictions may apply).
          </Body1>
          <Body1 className={styles.m8}>
            We will review and provide an email update promptly after submission.
          </Body1>

          <button
            className={classNames(styles.button, ButtonStyles.primary)}
            onClick={onClose}
            type='button'
          >
            Continue
          </button>

          <PumpkinNowServiceHours />
        </>
      ) : (
        <>
          <h5 id='modal-title'>We’re unable to expedite payment for this claim</h5>
          <Body1 id='modal-description'>
            We reserve this service for critical care claims with invoices of $
            {minimum} or more.
          </Body1>
          <Body1 className={styles.m8}>
            Simply continue to submit your request for reimbursement after you pay
            your vet bill and we’ll process it as soon as possible.
          </Body1>

          <button
            className={classNames(styles.button, ButtonStyles.primary)}
            onClick={onClose}
            type='button'
          >
            Continue to file claim for reimbursement
          </button>

          <LegalBody isBold>Have questions? We are here to help.</LegalBody>
          <LegalBody isBold className={styles.m8}>
            <a href={CONTACT_EMAIL_LINK}>{CONTACT_EMAIL}</a>
            {' / '}
            <a href={PHONE_NUMBER_LINK}>{FORMATTED_PHONE_NUMBER}</a>
          </LegalBody>
        </>
      )}
    </Modal>
  );
}

PumpkinNowQualifiedModal.propTypes = {
  isEstimate: PropTypes.bool,
  isQualified: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  minimum: PropTypes.number.isRequired,
};

export default PumpkinNowQualifiedModal;
