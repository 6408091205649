import PropTypes from 'prop-types';

import { IS_VET } from '@pumpkincare/config';
import { Body2, Modal } from '@pumpkincare/shared/ui';

import { MEMBER_TERMS, STATE_TERMS, VET_TERMS } from './claims-terms-content';

import styles from './claims-terms-modal.css';

function ClaimsTermsModal({ handleClose }) {
  const context_terms = IS_VET ? VET_TERMS : MEMBER_TERMS;
  const terms = [...context_terms, ...STATE_TERMS];

  return (
    <Modal
      onClose={handleClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      classes={{ content: styles.modalContainer }}
    >
      <h3 id='modal-title'>Pumpkin Claims Fraud Notice</h3>
      {terms.map(({ id, copy }, index) => {
        const props = index === 0 ? { id: 'modal-description' } : {};

        return (
          <Body2 key={id} className={styles.legalText} {...props}>
            {copy}
          </Body2>
        );
      })}
    </Modal>
  );
}

ClaimsTermsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ClaimsTermsModal;
