import { useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { IS_VET } from '@pumpkincare/config';
import { getIsPetSawOnly } from '@pumpkincare/user';

import { AMOUNT_CLAIMED } from './claim-visit-details/claim-visit-details-shared';
import ClaimConditionsServices from './claim-conditions-services';
import ClaimRecordsSubmit from './claim-records-submit';
import ClaimRequestType from './claim-request-type';
import ClaimVisitDetails from './claim-visit-details';
import PumpkinNowQualifiedModal from './pumpkin-now-qualified-modal';

import styles from './claim-form.css';

// which steps to show and when
const STEPS_PUMPKIN_NOW = [0, 1, 2, 3];
const STEPS = [0, 0, 1, 2];

const PUMPKIN_NOW_MINIMUM = 1000;
function getIsQualifiedForPumpkinNow(data) {
  const { request, visit } = data;
  const isOverMinimum = visit?.[AMOUNT_CLAIMED] >= PUMPKIN_NOW_MINIMUM;

  return {
    isQualifiedForPumpkinNow: request?.isEstimate || isOverMinimum,
    isNotQualifiedForPaymentAtVet: request?.isForPaymentAtVet && !isOverMinimum,
  };
}

// Prop-drilling from parent so that this can be used in MC or PawPortal
function ClaimForm({
  conditions,
  incidents,
  pet,
  zipcode,
  onSubmit,
  isSubmitting,
  isProcessingFile,
  onUploadFile,
  isEstimate: propsIsEstimate,
  isRtpEnabled,
  rtpRedirect,
}) {
  const [step, setStep] = useState(0);
  const [pumpkinNowQualifiedModalOpen, setPumpkinNowQualifiedModalOpen] = useState({
    isOpen: false,
  });

  const { mvx66PumpkinNow } = useFlags();
  const isPetWellnessOnly = getIsPetSawOnly(pet);
  const hasRequestTypeModule = mvx66PumpkinNow && !isPetWellnessOnly;
  const stepsOrder = hasRequestTypeModule ? STEPS_PUMPKIN_NOW : STEPS;

  const formData = useRef(
    hasRequestTypeModule
      ? {}
      : { request: { isEstimate: false, isForPaymentAtVet: false } }
  );
  const formRef = useRef();

  const isEstimate = mvx66PumpkinNow
    ? formData.current.request?.isEstimate
    : propsIsEstimate;

  function handleSubmit(data) {
    formData.current = { ...formData.current, ...data };

    const finalStep = stepsOrder[stepsOrder.length - 1];
    if (step !== finalStep) {
      const { isQualifiedForPumpkinNow, isNotQualifiedForPaymentAtVet } =
        getIsQualifiedForPumpkinNow(formData.current);
      /*
     BE will do its own determination for isEligible, this is only for the first FE check
     */
      formData.current.request.isEligibleForPumpkinNow = isQualifiedForPumpkinNow;

      setPumpkinNowQualifiedModalOpen({
        isQualifiedForPumpkinNow,
        isNotQualifiedForPaymentAtVet,
        isOpen:
          mvx66PumpkinNow &&
          step === stepsOrder[1] &&
          ((isQualifiedForPumpkinNow && !IS_VET) || isNotQualifiedForPaymentAtVet),
      });

      setStep(state => state + 1);
      window.scrollTo({ top: formRef.current.offsetTop, behavior: 'smooth' });
      return;
    }

    onSubmit(formData);
  }

  function handleClosePumpkinNowQualifiedModal() {
    const { isNotQualifiedForPaymentAtVet } = pumpkinNowQualifiedModalOpen;

    if (isNotQualifiedForPaymentAtVet)
      formData.current.request.isForPaymentAtVet = false;

    setPumpkinNowQualifiedModalOpen({ isOpen: false });
  }

  return (
    <div className={styles.root} ref={formRef}>
      {hasRequestTypeModule ? (
        <ClaimRequestType
          pet={pet}
          zipcode={zipcode}
          isOpen={step === stepsOrder[0]}
          canEdit={step > stepsOrder[0]}
          onSubmit={handleSubmit}
          onEditClick={() => setStep(stepsOrder[0])}
          isComplete={!!formData.current.request}
          isProcessingClaimAttachment={isProcessingFile}
          onUploadFile={onUploadFile}
          isRtpEnabled={isRtpEnabled}
          rtpRedirect={rtpRedirect}
        />
      ) : null}

      <ClaimVisitDetails
        pet={pet}
        zipcode={zipcode}
        isOpen={step === stepsOrder[1]}
        canEdit={step > stepsOrder[1]}
        onSubmit={handleSubmit}
        onEditClick={() => setStep(stepsOrder[1])}
        isComplete={!!formData.current.visit}
        isProcessingClaimAttachment={isProcessingFile}
        onUploadFile={onUploadFile}
      />

      {pumpkinNowQualifiedModalOpen.isOpen ? (
        <PumpkinNowQualifiedModal
          isQualified={pumpkinNowQualifiedModalOpen.isQualifiedForPumpkinNow}
          onClose={handleClosePumpkinNowQualifiedModal}
          isEstimate={isEstimate}
          minimum={PUMPKIN_NOW_MINIMUM}
        />
      ) : null}

      <ClaimConditionsServices
        pet={pet}
        conditions={conditions}
        incidents={incidents}
        isOpen={step === stepsOrder[2]}
        canEdit={step > stepsOrder[2]}
        onSubmit={handleSubmit}
        onEditClick={() => setStep(stepsOrder[2])}
        isComplete={!!formData.current.conditions}
      />

      <ClaimRecordsSubmit
        isOpen={step === stepsOrder[3]}
        pet={pet}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onUploadFile={onUploadFile}
        isEstimate={isEstimate}
      />
    </div>
  );
}

ClaimForm.defaultProps = {
  conditions: [],
  incidents: [],
  zipcode: '',
};

ClaimForm.propTypes = {
  conditions: PropTypes.array,
  isSubmitting: PropTypes.bool,
  isProcessingFile: PropTypes.bool,
  isEstimate: PropTypes.bool,
  isRtpEnabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  pet: PropTypes.object.isRequired,
  rtpRedirect: PropTypes.string,
  zipcode: PropTypes.string,
};

export default ClaimForm;
