import PropTypes from 'prop-types';

import { IS_VET } from '@pumpkincare/config';
import {
  Body1,
  Bolt,
  ButtonStyles,
  ChevronRight,
  Modal,
  RouteLink,
} from '@pumpkincare/shared/ui';

import styles from './rtp-unavailable-modal.css';

const MEMBER_COPY = [
  'If your bank isn’t compatible, we’ll still expedite this claim for processing and reimbursement.',
  'Please note, it could take 1-3 business days for the funds to appear in your account.',
];

const VET_COPY = [
  'If the bank we have on file for your client isn’t compatible, we’ll still expedite this claim for processing and reimbursement.',
  'Please note, it could take 1-3 business days for the funds to appear in their account.',
];

function RtpUnavailableModal({ onClose, rtpRedirect }) {
  const copy = IS_VET ? VET_COPY : MEMBER_COPY;

  return (
    <Modal
      onClose={onClose}
      classes={{ container: styles.root, content: styles.content }}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Bolt width={45} height={80} viewBox='0 0 45 80' className={styles.bolt} />

      <h5 id='modal-title'>
        PumpkinNow's urgent pay service requires a bank that is compatible with
        real-time payments
      </h5>

      <div>
        <Body1 id='modal-description' style={{ marginBottom: '8px' }}>
          {copy[0]}
        </Body1>
        <Body1>{copy[1]}</Body1>
      </div>

      {rtpRedirect ? (
        <RouteLink to={rtpRedirect} color='tertiary'>
          Set up a compatible bank account <ChevronRight />
        </RouteLink>
      ) : null}

      <button className={ButtonStyles.primary} onClick={onClose} type='button'>
        Continue to file claim for reimbursement
      </button>
    </Modal>
  );
}

RtpUnavailableModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  rtpRedirect: PropTypes.string,
};

export default RtpUnavailableModal;
