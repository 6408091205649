import { useQuery } from 'react-query';

import { getIsLoggedIn, ONE_HOUR } from '@pumpkincare/shared';

import { getOnboardingSteps } from './onboarding-service';

export const ONBOARDING_QUERY = 'onboarding';

export function useOnboardingSteps(options = {}) {
  return useQuery(
    [ONBOARDING_QUERY],

    getOnboardingSteps,

    {
      enabled: getIsLoggedIn(),

      placeholderData: [],
      staleTime: ONE_HOUR,

      ...options,
    }
  );
}
