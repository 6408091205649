import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body2, ButtonStyles, Chip, Typography } from '@pumpkincare/shared/ui';

import AboutPumpkinNow from '../../about-pumpkin-now';
import ClaimFormAccordion from '../claim-form-accordion';
import { CLAIM_TYPE } from './claim-request-type-shared';
import RtpUnavailableModal from './rtp-unavailable-modal';

import styles from './claim-request-type.css';

const CHIPS = [
  { type: CLAIM_TYPE.estimate, copy: 'An estimate for pre-approval' },
  { type: CLAIM_TYPE.claim, copy: 'A claim for reimbursement' },
  { type: CLAIM_TYPE.forPayment, copy: 'A claim for payment at the vet*' },
];

function ClaimRequestType({
  canEdit,
  isComplete,
  isOpen,
  onSubmit,
  onEditClick,
  isRtpEnabled,
  rtpRedirect,
}) {
  const [formData, setFormData] = useState({
    [CLAIM_TYPE.name]: '',
  });
  const [hasError, setHasError] = useState(false);
  const [isRtpUnavailableModalOpen, setIsRtpUnavailableModalOpen] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    if (!formData[CLAIM_TYPE.name]) {
      setHasError(true);
      return;
    }

    const isForPaymentAtVet = formData[CLAIM_TYPE.name] === CLAIM_TYPE.forPayment;
    if (!isRtpEnabled && isForPaymentAtVet) {
      setIsRtpUnavailableModalOpen(true);
      return;
    }

    onSubmit({
      request: {
        isEstimate: formData[CLAIM_TYPE.name] === CLAIM_TYPE.estimate,
        isForPaymentAtVet,
      },
    });
  }

  function handleCloseModal() {
    onSubmit({
      request: {
        isEstimate: false,
        isForPaymentAtVet: false,
      },
    });
    setFormData({ [CLAIM_TYPE.name]: CLAIM_TYPE.claim });
    setIsRtpUnavailableModalOpen(false);
  }

  function handleChipClick(value) {
    setHasError(false);
    setFormData({
      [CLAIM_TYPE.name]: value,
    });
  }

  return (
    <>
      <ClaimFormAccordion
        canEdit={canEdit}
        isComplete={isComplete}
        isOpen={isOpen}
        title='Request type'
        onEditClick={onEditClick}
      >
        <form className={styles.root} onSubmit={handleSubmit}>
          <div>
            <Body2 isBold>I'm here to submit</Body2>

            <div className={styles.chips}>
              {CHIPS.map(chip => (
                <Chip
                  key={chip.type}
                  onClick={() => handleChipClick(chip.type)}
                  selected={formData[CLAIM_TYPE.name] === chip.type}
                  classes={{ root: classNames(styles.chip, Typography.body2) }}
                  type='button'
                  error={{ hasError }}
                >
                  {chip.copy}
                </Chip>
              ))}
              {hasError ? (
                <Body2 className={styles.error}>
                  Please select request type to proceed
                </Body2>
              ) : null}

              <AboutPumpkinNow
                ctaCopy='Learn about our urgent pay service'
                ctaColor='ctaSmall'
                isIcon={false}
                classes={{ root: styles.aboutPumpkinNow }}
              />
            </div>
          </div>

          <button className={ButtonStyles.primary} type='submit'>
            Next
          </button>
        </form>
      </ClaimFormAccordion>

      {isRtpUnavailableModalOpen ? (
        <RtpUnavailableModal onClose={handleCloseModal} rtpRedirect={rtpRedirect} />
      ) : null}
    </>
  );
}

ClaimRequestType.propTypes = {
  canEdit: PropTypes.bool,
  isComplete: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isRtpEnabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onEditClick: PropTypes.func,
  rtpRedirect: PropTypes.string,
};

export default ClaimRequestType;
