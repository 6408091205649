export const RECORDS_LIST = 'recordsList';
export const TERMS = 'terms';
export const ADDITIONAL_PHONE = 'additionalPhone';
export const VET_AUDIT_NAME = 'vetAuditName';

export const RECORDS_SUBMIT_INITIAL_STATE = {
  [RECORDS_LIST]: [],
  [ADDITIONAL_PHONE]: '',
  [VET_AUDIT_NAME]: '',
};
