export const MEMBER_TERMS = [
  {
    id: 'intro',
    copy: "I confirm to the best of my knowledge the above statements are true in every respect. I understand that the fees listed may not be covered or may exceed my plan benefit. I understand that I am financially responsible to my veterinarian for the entire treatment. I understand that this claim cannot be adjusted without itemized receipts. I also understand that the deliberate misrepresentation of the animal's condition or the omission of any material facts may result in the denial of the claim and/or the cancellation of coverage. I authorize United States Fire Insurance Company and its business partners to review and obtain a copy of ALL RECORDS including the insurance claim records and medical records as to examination, history, diagnosis, treatment and prognosis with respect to any condition. I further authorize these entities to disclose identifying information about me and my pet, as well as information about my claim experience, to my veterinarian.",
  },
  {
    id: 'important-notice',
    copy: 'Important Notice',
  },
  {
    id: 'notice-to-claimants',
    copy: 'NOTICE TO CLAIMANTS: Any person who, with the intent to defraud or knowingly facilitates a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement, or conceals information for the purpose of Misleading may be guilty of insurance fraud and subject to criminal and/or civil penalties.',
  },
  {
    id: 'pre-approval',
    copy: 'PRE-APPROVAL OF AN ESTIMATE IS NOT A GUARANTEE OF COVERAGE. This is not a bill. This statement is called an Insurance Pre-Authorization Summary. Allowed amounts are subject to change based on actual services rendered on submitted claim form upon final review. This Pre-authorization and Estimate is void after 30 days from the date of completion or termination of the current policy period or the effective date of any midterm policy changes. Coverage is subject to your co-pay and any applicable deductible up to the limit of liability. This summary is based on currently available information. Pre-authorization is void if the diagnosis is found to be an ineligible condition or secondary to an ineligible condition.',
  },
];

export const VET_TERMS = [
  {
    id: 'intro',
    copy: "I confirm to the best of my knowledge the above statements are true in every respect. I understand that this claim cannot be adjusted without itemized receipts. I also understand that the deliberate misrepresentation of the animal's condition or the omission of any material facts may result in the denial of the claim and/or the cancellation of coverage. I authorize United States Fire Insurance Company and its business partners to review and obtain a copy of ALL RECORDS including the insurance claim records and medical records as to examination, history, diagnosis, treatment and prognosis with respect to any condition.",
  },
  {
    id: 'notice-to-claimants',
    copy: 'NOTICE TO CLAIMANTS: Any person who, with the intent to defraud or knowingly facilitates a fraud against an insurer, submits an application or files a Claim containing a false or deceptive statement, or conceals information for the purpose Misleading of may be guilty of insurance fraud and subject to criminal and/or civil penalties.',
  },
  {
    id: 'pre-approval',
    copy: 'PRE-APPROVAL OF AN ESTIMATE IS NOT A GUARANTEE OF COVERAGE. This is not a bill. This statement is called an Insurance Pre-Authorization Summary. Allowed amounts are subject to change based on actual services rendered on submitted claim form upon final review. This Pre-authorization and Estimate is void after 30 days from the date of completion or termination of the current policy period or the effective date of any midterm policy changes. Coverage is subject to your co-pay and any applicable deductible up to the limit of liability. This summary is based on currently available information. Pre-authorization is void if the diagnosis is found to be an ineligible condition or secondary to an ineligible condition.',
  },
];

export const STATE_TERMS = [
  {
    id: 'california',
    copy: 'NOTICE TO CALIFORNIA APPLICANTS: Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.',
  },
  {
    id: 'connecticut',
    copy: 'NOTICE TO CONNECTICUT APPLICANTS: Concealment, fraud. This entire policy shall be void if, whether before or after a loss, the insured has willfully concealed or misrepresented any material fact or circumstance concerning this insurance or the subject thereof, or the interest of the insured therein, or in case of any fraud or false swearing by the insured relating thereto.',
  },
  {
    id: 'colorado',
    copy: 'NOTICE TO COLORADO APPLICANTS: It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policy holder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado Division of Insurance within the Department of Regulatory Agencies.',
  },
  {
    id: 'dc',
    copy: 'NOTICE TO DISTRICT OF COLUMBIA APPLICANTS: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information material related to a claim was provided by the applicant.',
  },
  {
    id: 'florida',
    copy: 'NOTICE TO FLORIDA APPLICANTS: Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.',
  },
  {
    id: 'hawaii',
    copy: 'NOTICE TO HAWAII APPLICANTS: For your protection, Hawaii law requires you to be informed that presenting a fraudulent claim for payment of a loss or benefit is a crime punishable by fines or imprisonment, or both.',
  },
  {
    id: 'illinois',
    copy: 'NOTICE TO ILLINOIS APPLICANTS: A person who knowingly makes any false or fraudulent statement or presentation in or with reference to any application, or for the purpose of obtaining any fee, commission, money, or benefit from or in any company transacting business under this article, commits a Class A misdemeanor.',
  },
  {
    id: 'kansas',
    copy: 'NOTICE TO KANSAS APPLICANTS: Fraudulent insurance act means an act committed by any person who, knowingly and with intent to defraud, presents, causes to be presented or prepares with knowledge or belief that it will be presented to or by an insurer, purported insurer, broker or any agent thereof, any written, electronic, electronic impulse, facsimile, magnetic, oral, or telephonic communication statement as part of, or in support of, an application for the issuance of, or the rating of an insurance policy for personal or commercial insurance, or a claim for payment or other benefit pursuant to an insurance policy for commercial or personal insurance which such person knows to contain materially false information concerning any fact material thereto; or conceals, for the purpose of misleading, information concerning any fact material thereto.',
  },
  {
    id: 'kentucky',
    copy: 'NOTICE TO KENTUCKY APPLICANTS: Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act which is a crime.',
  },
  {
    id: 'louisiana',
    copy: 'NOTICE TO LOUISIANA APPLICANTS: Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  },
  {
    id: 'maine',
    copy: 'NOTICE TO MAINE APPLICANTS: It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or a denial of insurance benefits',
  },
  {
    id: 'maryland',
    copy: 'NOTICE TO MARYLAND APPLICANTS: Any person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  },
  {
    id: 'new-mexico',
    copy: 'NOTICE TO NEW MEXICO APPLICANTS: Any person who knowingly presents a false or fraudulent claim for payment for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to civil fines and criminal penalties.',
  },
  {
    id: 'new-hampshire',
    copy: 'NOTICE TO NEW HAMPSHIRE RESIDENTS: Any person who, with a purpose to injure, defraud, or deceive any insurance company, files a statement of claim containing any false, incomplete, or misleading information is subject to prosecution and punishment for insurance fraud, as provided in RSA 638:20.',
  },
  {
    id: 'new-jersey',
    copy: 'NOTICE TO NEW JERSEY APPLICANTS: Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.',
  },
  {
    id: 'new-york',
    copy: 'NOTICE TO NEW YORK APPLICANTS: Any person who knowingly and with intent to defraud an insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation.',
  },
  {
    id: 'ohio',
    copy: 'NOTICE TO OHIO APPLICANTS: Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud',
  },
  {
    id: 'oklahoma',
    copy: 'NOTICE TO OKLAHOMA APPLICANTS: WARNING: Any person who knowingly, and with intent to injure, defraud or deceive any insurer makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.',
  },
  {
    id: 'pennsylvania',
    copy: 'NOTICE TO PENNSYLVANIA APPLICANTS: Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.',
  },
  {
    id: 'tennessee',
    copy: 'NOTICE TO TENNESSEE APPLICANTS: It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.',
  },
  {
    id: 'virginia',
    copy: 'NOTICE TO VIRGINIA APPLICANTS: It is a crime to knowingly provide false incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines, or denial of insurance benefits.',
  },
];
