import classNames from 'classnames';
import PropTypes from 'prop-types';

import { IS_VET } from '@pumpkincare/config';
import {
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  FORMATTED_PARENTHESES_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
  PUMPKIN_NOW_HOURS,
  useBooleanInput,
} from '@pumpkincare/shared';
import { Body1, Bolt, ButtonStyles, Modal } from '@pumpkincare/shared/ui';

import styles from './about-pumpkin-now.css';

/*
  so that changes to ButtonStyles are discoverable, rather than ButtonStyles[color]
 */
const COLORS_MAP = {
  primary: ButtonStyles.primary,
  secondary: ButtonStyles.secondary,
  tertiary: ButtonStyles.tertiary,
  cta: ButtonStyles.cta,
  ctaSmall: ButtonStyles.ctaSmall,
};

const ABOUT = {
  petOwner:
    'PumpkinNow™ is an urgent pay service from Pumpkin Pet Insurance. If your pet needs critical or high-cost veterinary care – we can pay you before you pay the vet for the covered bill.',
  vet: 'PumpkinNow™ is an urgent pay service from Pumpkin Pet Insurance. If a pet needs critical, high-cost veterinary care, we can pay your client before they pay you for the covered bill.',
};

const HOW_IT_WORKS = {
  petOwner: [
    'Ask for the final invoice for any $1000+ treatment before paying your vet',
    'Submit a ‘claim for payment at the vet’ with the unpaid invoice and hold tight',
    '(Keep an eye on your email for updates)',
    'Get the funds in your account in minutes* for covered services to pay your vet',
  ],
  vet: [
    'Provide the final invoice for any $1000+ treatment before your client pays their bill',
    'Submit a ‘claim for payment at the vet’ with the unpaid invoice and hold tight',
    '(Keep an eye on your email for updates)',
    'Your client will get the funds in their account in minutes* for covered services to pay their bill',
  ],
};

const ELIGIBILITY = [
  'All prior medical records available and reviewed by Pumpkin.',
  'The claim submitted is for an invoice of $1,000 or more.',
  'A compatible bank account available on file for real time payments.',
];

function AboutPumpkinNow({ isIcon, ctaCopy, ctaColor, classes }) {
  const [isModalOpen, toggleIsModalOpen] = useBooleanInput(false);

  return (
    <>
      <button
        className={classNames(COLORS_MAP[ctaColor], classes.root)}
        onClick={toggleIsModalOpen}
        type='button'
      >
        {isIcon ? <Bolt /> : null}
        {ctaCopy}
      </button>

      {isModalOpen ? (
        <Modal
          onClose={toggleIsModalOpen}
          classes={{ container: styles.container }}
          aria-labelledby='modal-title'
          aria-describedby='modal-description'
        >
          <div className={styles.content}>
            <div className={styles.section}>
              <h5 id='modal-title'>
                About PumpkinNow<span className={styles.light}>™</span>
              </h5>
              <Body1 id='modal-description'>
                {IS_VET ? ABOUT.vet : ABOUT.petOwner}
              </Body1>
            </div>

            <div className={styles.section}>
              <Body1 isBold>How It Works</Body1>
              {HOW_IT_WORKS[IS_VET ? 'vet' : 'petOwner'].map(item => (
                <Body1 key={item}>{item}</Body1>
              ))}
            </div>

            <div className={styles.section}>
              <Body1 isBold>* Eligibility for expedited payment</Body1>
              {ELIGIBILITY.map(item => (
                <Body1 key={item}>{item}</Body1>
              ))}
            </div>

            <div className={styles.section}>
              <Body1 isBold>PumpkinNow™ Service Hours</Body1>
              {PUMPKIN_NOW_HOURS.map(hour => (
                <Body1 key={hour}>{hour}</Body1>
              ))}
              <Body1>
                Please note: This usually takes around 15 minutes. If filing outside
                service hours, we will process it on the next business day.
              </Body1>
            </div>

            <div className={styles.section}>
              <Body1 isBold>Have questions? We are here to help.</Body1>
              <Body1 isBold>
                <a href={CONTACT_EMAIL_LINK}>{CONTACT_EMAIL}</a>
                {' / '}
                <a href={PHONE_NUMBER_LINK}>{FORMATTED_PARENTHESES_PHONE_NUMBER}</a>
              </Body1>
            </div>

            <div className={styles.section}>
              <button
                className={ButtonStyles.secondary}
                onClick={toggleIsModalOpen}
                type='button'
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}

AboutPumpkinNow.defaultProps = {
  isIcon: true,
  ctaCopy: 'Learn about our urgent pay service',
  ctaColor: 'tertiary',
  classes: {},
};

AboutPumpkinNow.propTypes = {
  classes: PropTypes.object,
  isIcon: PropTypes.bool,
  ctaCopy: PropTypes.string,
  ctaColor: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'cta', 'ctaSmall']),
};

export default AboutPumpkinNow;
