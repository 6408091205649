import { useState } from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { IS_VET } from '@pumpkincare/config';
import { useBooleanInput } from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  Checkbox,
  FileUpload,
  LegalBody,
  LoaderButton,
  TextField,
  Typography,
} from '@pumpkincare/shared/ui';

import ClaimFormAccordion from '../claim-form-accordion';
import {
  ADDITIONAL_PHONE,
  RECORDS_LIST,
  RECORDS_SUBMIT_INITIAL_STATE,
  TERMS,
  VET_AUDIT_NAME,
} from './claim-records-submit-shared';
import ClaimsMedicalRecordModal from './claims-medical-record-modal';
import ClaimsTermsModal from './claims-terms-modal';

import styles from './claim-records-submit.css';

import infoIcon from '../info_icon.svg';

function ClaimRecordsSubmit({
  isOpen,
  onSubmit,
  isSubmitting,
  onUploadFile,
  pet,
  isEstimate,
}) {
  const { mvx66PumpkinNow } = useFlags();

  const [formData, setFormData] = useState(RECORDS_SUBMIT_INITIAL_STATE);
  const [errors, setErrors] = useState({});
  const [isAcceptedTerms, toggleAcceptTerms] = useBooleanInput(false);
  const [isTermsModalOpen, toggleTermsModal] = useBooleanInput(false);
  const [isMedicalRecordModalOpen, toggleMedicalRecordModal] =
    useBooleanInput(false);

  const isSawOnly = !!pet.wellness.length && !pet.policies.length;

  function handleSubmit() {
    const errors = {};
    if (!isAcceptedTerms) {
      errors[TERMS] = 'You must accept the declaration and fraud notice';
    }

    if (mvx66PumpkinNow && IS_VET && !formData[VET_AUDIT_NAME]) {
      errors[VET_AUDIT_NAME] = 'Please share who is submitting this request';
    }

    if (Object.keys(errors).length) {
      return setErrors(errors);
    }

    // pass in uploaded files
    onSubmit({
      submit: {
        records: formData[RECORDS_LIST],
        vetAuditName: formData[VET_AUDIT_NAME],
        additionalContactPhone: formData[ADDITIONAL_PHONE],
      },
    });
  }

  function deleteError(key) {
    setErrors(state => ({
      ...state,
      [key]: '',
    }));
  }

  function handleChange(event) {
    const { name, value } = event.target || event;

    setFormData(state => ({
      ...state,
      [name]: value,
    }));

    deleteError(name);
  }

  function handleOpenTermsModal(e) {
    e.stopPropagation();
    e.preventDefault();

    toggleTermsModal();
  }

  function handleOpenMedicalRecordModal(e) {
    e.stopPropagation();
    e.preventDefault();

    toggleMedicalRecordModal();
  }

  function handleCheckTerms(e) {
    toggleAcceptTerms(e);
    deleteError(TERMS);
  }

  const claimType = isEstimate ? 'estimate' : 'claim';

  return (
    <ClaimFormAccordion isOpen={isOpen} title='Medical Records & Submit'>
      <div className={styles.root}>
        <div>
          <Body2 isBold style={{ marginBottom: '8px' }}>
            Upload {pet.name}'s Latest Medical Records (Optional)
          </Body2>
          <Body2>
            {isSawOnly
              ? `Share ${pet.name}'s medical records to gain a comprehensive understanding of ${pet.genderGrammar.possessive} health condition. This information will enable us to better assess ${pet.genderGrammar.possessive} overall well-being.`
              : `
            Speed up ${pet.name}’s ${claimType} by including
            medical records from this visit or past visits that you have not yet shared
            with Pumpkin.
          `}
          </Body2>

          <button
            className={classNames(
              styles.modalButton,
              ButtonStyles.unset,
              Typography.body2
            )}
            onClick={handleOpenMedicalRecordModal}
            data-testid='medicalRecordsInfo'
          >
            <img src={infoIcon} alt='' />
            {''}
            What are medical records?
          </button>

          <FileUpload
            files={formData[RECORDS_LIST]}
            classes={{
              root: classNames(styles.fileUpload, {
                [styles.fileError]: errors[RECORDS_LIST],
              }),
            }}
            onFileListChange={value => handleChange({ name: RECORDS_LIST, value })}
            onError={message => {
              if (message) {
                setErrors(errors => ({
                  ...errors,
                  [RECORDS_LIST]: message,
                }));
              }
            }}
            error={errors[RECORDS_LIST]}
            onUpload={(file, rawFile) => {
              deleteError(RECORDS_LIST);
              return onUploadFile(file, rawFile, 'medical-record');
            }}
          />

          {!mvx66PumpkinNow ? <div className={styles.divider} /> : null}
        </div>

        {mvx66PumpkinNow ? (
          <TextField
            id={ADDITIONAL_PHONE}
            name={ADDITIONAL_PHONE}
            value={formData[ADDITIONAL_PHONE]}
            onChange={handleChange}
            label='Add a phone number (optional)'
            legalMessage={
              <LegalBody className={styles.legal}>
                The best number to contact {IS_VET ? 'your client' : 'you'} while
                processing this request
              </LegalBody>
            }
            error={{ errorMessage: errors[ADDITIONAL_PHONE] }}
          />
        ) : null}

        {mvx66PumpkinNow && IS_VET ? (
          <TextField
            id={VET_AUDIT_NAME}
            name={VET_AUDIT_NAME}
            value={formData[VET_AUDIT_NAME]}
            onChange={handleChange}
            label='Submitted by'
            placeholder='First & Last Name'
            legalMessage={
              <LegalBody className={styles.legal}>
                Thank you for going the extra mile for your hospital’s insured pets{' '}
                <span>❤️</span>
              </LegalBody>
            }
            error={{ errorMessage: errors[VET_AUDIT_NAME] }}
          />
        ) : null}

        <Checkbox
          label={
            <LegalBody>
              I have read and accept the{' '}
              <button
                className={classNames(styles.declaration, ButtonStyles.cta)}
                onClick={handleOpenTermsModal}
              >
                Pumpkin Claims Fraud Notice
              </button>
              {IS_VET
                ? '. I affirm that I have permission from the Pumpkin policyholder to submit a claim on their behalf, and that the information contained therein is accurate and complete.'
                : '.'}
            </LegalBody>
          }
          onChange={handleCheckTerms}
          checked={isAcceptedTerms}
          classes={{
            root: classNames(styles.termsCheckbox, {
              [styles.vetTerms]: IS_VET,
            }),
          }}
          isError={!!errors[TERMS]}
        />

        {errors[TERMS] ? (
          <LegalBody className={styles.error} data-testid='terms-error'>
            {errors[TERMS]}
          </LegalBody>
        ) : null}

        <LoaderButton
          isLoading={isSubmitting}
          onClick={handleSubmit}
          color='primary'
        >
          Submit
        </LoaderButton>
      </div>

      {isMedicalRecordModalOpen ? (
        <ClaimsMedicalRecordModal handleClose={toggleMedicalRecordModal} />
      ) : null}
      {isTermsModalOpen ? <ClaimsTermsModal handleClose={toggleTermsModal} /> : null}
    </ClaimFormAccordion>
  );
}

ClaimRecordsSubmit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isEstimate: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  pet: PropTypes.object.isRequired,
};

export default ClaimRecordsSubmit;
