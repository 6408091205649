import { formatMMDDYYYYtoYYYYMMDD } from '@pumpkincare/shared';

function fileToClaimFile(file, document_type) {
  return {
    name: file.name,
    type: file.type,
    description: '',
    key: file.key,
    document_type: document_type,
  };
}

export function transformClaim({
  fileList,
  medicalRecordsFileList,
  dateList,
  injuryDate,
  totalAmountList,
  pets,
  claimType,
  isOngoing,
  isInsuranceAccident,
  isInsuranceIllness,
  isPreventiveEssentials,
  isMultiVet,
  selectedPet,
  selectedReimbursement,
  vetVisitReason,
  selectedVet,
}) {
  const fileUploadList = fileList
    .filter(file => file.error === undefined)
    .map(file => {
      return fileToClaimFile(file, 'invoice');
    })
    .concat(
      medicalRecordsFileList
        .filter(file => file.error === undefined)
        .map(file => {
          return fileToClaimFile(file, 'medical_record');
        })
    );

  const totalAmountClaimedList = totalAmountList.map(item =>
    Math.round(parseFloat(item.totalAmountClaimed) * 100)
  );

  const pet = pets.find(pet => pet.id === selectedPet);

  const claim = {
    is_estimate: claimType === 'estimate',
    is_accident: isInsuranceAccident,
    is_illness: isInsuranceIllness,
    is_prevent: isPreventiveEssentials,
    pet_id: selectedPet,
    claim_amounts: totalAmountClaimedList,
    send_reimbursement_to_vet: selectedReimbursement === 'my_vet',
    diagnosis_story: vetVisitReason,
    claim_attachments: fileUploadList,
    treatment_dates: dateList.map(item =>
      formatMMDDYYYYtoYYYYMMDD(item.dateOfVisit)
    ),
  };

  if (selectedVet && selectedVet !== 'other_vet') {
    claim.vet_id = selectedVet;
  }

  //ALLOW_MULTI_VET_PER_PET
  if ((isInsuranceAccident || isInsuranceIllness) && injuryDate) {
    claim.injury_date = formatMMDDYYYYtoYYYYMMDD(injuryDate);
  }

  //CLAIMS_UPDATES
  if (
    (isInsuranceAccident || isInsuranceIllness) &&
    typeof isOngoing === 'boolean' &&
    typeof isMultiVet === 'boolean'
  ) {
    claim.is_ongoing = isOngoing;
    claim.is_multi_vet = isMultiVet;
  }

  return { claim: claim, petName: pet.name };
}

function transformClaimInvoiceSubClaim(subClaim) {
  return {
    claimedAmount: subClaim.claimed_amount,
    id: subClaim.id,
    type: subClaim.type,
    paidAmount: subClaim.total_reimbursible,
  };
}

function transformClaimInvoicePet(pet) {
  return {
    age: pet?.age,
    birthDate: pet.birth_date,
    breedCode: pet?.breed_code,
    breedName: pet?.breed_name,
    breedType: pet?.breed_type,
    gender: pet?.gender,
    hasMedicalRecords: pet.has_medical_records,
    id: pet.id,
    isPetPlanRenewalToAdult: pet.is_pet_plan_renewal_to_adult,
    medicalRecordsCount: pet.medical_records_count,
    name: pet.name,
    species: pet.species,
  };
}

export function transformClaimInvoice(claimInvoice) {
  return {
    claimedAmount: claimInvoice.claimed_amount,
    createdAt: claimInvoice.created_at,
    id: claimInvoice.id,
    lossDate: claimInvoice.loss_date,
    pet: transformClaimInvoicePet(claimInvoice.pet),
    petId: claimInvoice.pet.id,
    status: claimInvoice.status,
    summaryUrls: claimInvoice.summary_urls,
    isAccident: claimInvoice.is_accident,
    isIllness: claimInvoice.is_illness,
    isPrevent: claimInvoice.is_prevent,
    subClaims: claimInvoice.subclaims.map(transformClaimInvoiceSubClaim),
  };
}

function extractConditionsData(conditionList, conditionType = null) {
  return conditionList.map(condition => {
    return {
      type: conditionType || condition.type.toUpperCase(),
      onset_date: condition.onset_date || new Date(condition.conditionDate),
      diagnosis_id: condition.diagnosis_id || condition.conditionType.diagnosis_id,
      sub_diagnosis_id: condition.sub_diagnosis_id || condition.conditionType.id,
      incident_history_id: condition.incident_history_id || null,
    };
  });
}

export function transformClaimPayload(payload) {
  const {
    claimedAmounts,
    claimAttachments,
    diagnosisStory,
    hasPrevent,
    isAccident,
    isEstimate,
    isIllness,
    lossDate,
    medicalRecordsFileList,
    petId,
    sendReimbursementToVet,
    visits,
  } = payload;
  const claimedAmountsParsed = claimedAmounts.map(item =>
    Math.round(parseFloat(item.totalAmountClaimed) * 100)
  );
  return {
    claimed_amount: claimedAmountsParsed.reduce((sum, amount) => sum + amount, 0),

    claim_attachments: claimAttachments
      .map(file => fileToClaimFile(file, 'invoice'))
      .concat(
        medicalRecordsFileList.map(file => fileToClaimFile(file, 'medical_record'))
      ),

    diagnosis_story: diagnosisStory,
    is_accident: isAccident,
    is_estimate: isEstimate,
    is_illness: isIllness,
    is_prevent: hasPrevent,

    // Loss date doesn't exist for prevent-only claims
    loss_date: lossDate.length > 0 ? formatMMDDYYYYtoYYYYMMDD(lossDate) : null,

    pet_id: petId,
    send_reimbursement_to_vet: sendReimbursementToVet,

    visits: JSON.stringify(
      visits.map((visit, i) => ({
        claimed_amount: claimedAmountsParsed[i],
        date_of_visit: formatMMDDYYYYtoYYYYMMDD(visit.dateOfVisit),
      }))
    ),
  };
}

export function transformClaimFormData(claimFormData) {
  const { conditions, visit, submit, petId, request } = claimFormData;

  const { fileList } = visit;
  const { incidentHistory, accident, illness } = conditions;

  const incidents = extractConditionsData(incidentHistory).concat(
    extractConditionsData(accident, 'ACCIDENT'),
    extractConditionsData(illness, 'ILLNESS')
  );

  const claim_attachments = fileList
    .map(file => fileToClaimFile(file, 'invoice'))
    .concat(submit.records.map(file => fileToClaimFile(file, 'medical_record')));

  const claimed_amount = Math.round(parseFloat(visit.amountClaimed) * 100);

  const visits = JSON.stringify(
    visit.visitDate
      ? [
          {
            claimed_amount: claimed_amount,
            date_of_visit: formatMMDDYYYYtoYYYYMMDD(visit.visitDate),
          },
        ]
      : [
          {
            claimed_amount: claimed_amount,
            date_of_visit: new Date(visit.startDate).toISOString().slice(0, 10),
          },
          {
            claimed_amount: claimed_amount,
            date_of_visit: new Date(visit.endDate).toISOString().slice(0, 10),
          },
        ]
  );

  const lossDate = visit.visitDate ?? visit.startDate;

  return {
    claimed_amount,
    claim_attachments,
    diagnosis_story: conditions.visitReason,
    incidents,
    loss_date: formatMMDDYYYYtoYYYYMMDD(lossDate),
    is_multi_pet_invoice: !visit.onlyPet,
    send_reimbursement_to_vet: false,
    pet_id: petId,
    vet_practice_id: visit.vet?.id || null,
    vet_name: visit.vet?.vet_name || visit.vetName,
    vet_id: visit.vet?.id,
    is_illness: incidents.some(incident => incident.type === 'ILLNESS'),
    is_accident: incidents.some(incident => incident.type === 'ACCIDENT'),
    is_wellness: conditions.wellness ?? false,
    is_estimate: request.isEstimate,
    is_for_payment_at_vet: request.isForPaymentAtVet,
    additional_contact_phone: submit.additionalContactPhone,
    vet_audit_name: submit.vetAuditName,
    visits,
  };
}

export function transformClaimPayloadWithHeaders(payload) {
  const { data, headers } = payload;
  const contentHeader = headers['content-range'] ?? '';
  const [rangeData, claimsCount] = contentHeader.split('/');
  const [rangeFrom, rangeTo] = rangeData
    .replace(/[^\d.-]/g, '')
    .split('-')
    .map(item => parseInt(item));

  return {
    claims: data.body.map(transformClaimInvoice),
    contentRange: { rangeFrom, rangeTo, claimsCount },
  };
}

export function sortClaims(claims, orders = 'asc') {
  return claims.sort(function (a, b) {
    return orders === 'desc'
      ? new Date(b.createdAt) - new Date(a.createdAt)
      : new Date(a.createdAt) - new Date(b.createdAt);
  });
}
