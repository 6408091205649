import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  FORMATTED_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import {
  Body1,
  Bolt,
  CheckCircle,
  LegalBody,
  RouteLink,
} from '@pumpkincare/shared/ui';

import PumpkinNowServiceHours from '../pumpkin-now-service-hours';

import styles from './claim-form-success.css';

const SUBHEADER = {
  pumpkinNow:
    'A specialist will review your request and email you shortly to confirm if we are able to expedite processing (restrictions may apply).',
  regular: function (isEstimate) {
    return `A specialist will review your request and email you with any updates. You can also track the status of this ${
      isEstimate ? 'estimate' : 'claim'
    } from your account.`;
  },
};

function ClaimFormSuccess({
  classes,
  claimId,
  isEstimate,
  isEligibleForPumpkinNow,
  renderReimbursement,
  redirect,
}) {
  return (
    <div className={classNames(styles.root, classes.root)}>
      {isEligibleForPumpkinNow ? (
        <Bolt
          width={45}
          height={80}
          viewBox='0 0 45 80'
          className={styles.boltBig}
        />
      ) : (
        <CheckCircle
          color={'var(--astroGreen)'}
          width={90}
          height={90}
          viewBox='0 0 90 90'
          className={styles.check}
        />
      )}

      <h4>Thank you for submitting this {isEstimate ? 'estimate' : 'claim'}</h4>
      <Body1>
        {isEligibleForPumpkinNow
          ? SUBHEADER.pumpkinNow
          : SUBHEADER.regular(isEstimate)}
      </Body1>

      <div className={styles.reimbursement}>
        <LegalBody>Submission ID - #{claimId}</LegalBody>
        {renderReimbursement()}
      </div>

      {isEligibleForPumpkinNow ? (
        <PumpkinNowServiceHours classes={{ root: styles.hours }} />
      ) : null}

      <RouteLink to={redirect} color='primary' className={styles.redirect}>
        File another claim or estimate
      </RouteLink>

      <div>
        <LegalBody isBold>Have questions? We are here to help.</LegalBody>
        <LegalBody isBold className={styles.m8}>
          <a href={CONTACT_EMAIL_LINK}>{CONTACT_EMAIL}</a>
          {' / '}
          <a href={PHONE_NUMBER_LINK}>{FORMATTED_PHONE_NUMBER}</a>
        </LegalBody>
      </div>
    </div>
  );
}

ClaimFormSuccess.defaultProps = {
  classes: {},
};

ClaimFormSuccess.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  claimId: PropTypes.string.isRequired,
  isEstimate: PropTypes.bool.isRequired,
  isEligibleForPumpkinNow: PropTypes.bool.isRequired,
  renderReimbursement: PropTypes.func.isRequired,
  redirect: PropTypes.string.isRequired,
};

export default ClaimFormSuccess;
