// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pumpkin-now-service-hours_root_88ddbfad {\n    width: 100%;\n}\n\n.pumpkin-now-service-hours_align_743887d9 {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 4px;\n}\n\n.pumpkin-now-service-hours_align_743887d9 svg {\n    margin-right: -6px;\n}\n\n.pumpkin-now-service-hours_note_811984c4 {\n    margin-top: 16px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n    .pumpkin-now-service-hours_note_811984c4 {\n        max-width: 420px;\n        margin: 16px auto 0;\n    }\n}", "",{"version":3,"sources":["webpack://../../libs/claims/src/view/pumpkin-now-service-hours/pumpkin-now-service-hours.css"],"names":[],"mappings":"AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;QAChB,mBAAmB;IACvB;AACJ","sourcesContent":["@value tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n    width: 100%;\n}\n\n.align {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 4px;\n}\n\n.align svg {\n    margin-right: -6px;\n}\n\n.note {\n    margin-top: 16px;\n}\n\n@media tablet {\n    .note {\n        max-width: 420px;\n        margin: 16px auto 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"root": "pumpkin-now-service-hours_root_88ddbfad",
	"align": "pumpkin-now-service-hours_align_743887d9",
	"note": "pumpkin-now-service-hours_note_811984c4"
};
export default ___CSS_LOADER_EXPORT___;
