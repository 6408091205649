import { getReimbursementTargetAccountNumber } from './payment-query';
import { getPolicyConfig } from './policy-config-utils';

export const STEP_TYPE_VET_COLLECTION = 'VET_COLLECTION';
export const STEP_TYPE_FUNDING_SOURCE = 'FUNDING_SOURCE';
export const STEP_TYPE_PET_PHOTO = 'PET_PHOTO';

export function getIsStepForCancelledPet(step, pets) {
  const policies = pets.find(pet => pet.id === step.pet.id)?.policies;

  const {
    cancelled: { isCancelled },
  } = getPolicyConfig(policies);
  return isCancelled;
}

export function getIsUserOnboarded(onboardingData, pets, paymentsData) {
  if (!onboardingData.length) return true;

  // filter out if steps if already submitted, non VET_COLLECTION, or for a canceled policy
  const incompleteVetCollectionSteps = onboardingData.filter(step => {
    if (step.first_submitted_at || step.type !== 'VET_COLLECTION') return false;

    return !getIsStepForCancelledPet(step, pets);
  });

  const hasReimbursementAnswered = !!(
    onboardingData.find(step => step.type === STEP_TYPE_FUNDING_SOURCE)
      ?.first_submitted_at || getReimbursementTargetAccountNumber(paymentsData)
  );

  return !incompleteVetCollectionSteps.length && hasReimbursementAnswered;
}

export function sortStepsForActivePets(steps, pets) {
  const activeSteps = steps.filter(
    step =>
      step.type === STEP_TYPE_FUNDING_SOURCE || !getIsStepForCancelledPet(step, pets)
  );
  return activeSteps.sort((a, b) => {
    const typeOrder = {
      [STEP_TYPE_VET_COLLECTION]: 1,
      [STEP_TYPE_FUNDING_SOURCE]: 2,
      [STEP_TYPE_PET_PHOTO]: 3,
    };

    if (typeOrder[a.type] === typeOrder[b.type]) {
      if (a.pet && b.pet) {
        return a.pet.name.localeCompare(b.pet.name);
      }
      return 0;
    }

    return typeOrder[a.type] - typeOrder[b.type];
  });
}
